.get-started {
  background-color: #ffffff;
  padding: var(--space) 0;
  display: flex;
  justify-content: center;

  &__wrapper {
    padding: 160px 20px;
    background: linear-gradient(107.56deg, #41A8ED 0%, #0476C2 100%);
    border-radius: 20px;
  }

  &__title {
    text-align: center;
    margin-bottom: 64px;
    color: #ffffff;
  }

  .btn-300 {
    width: 300px;
    margin: auto;
  }
}
