.features-need {
  background-color: #fff;
  padding: var(--space) 0;

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;

    @include w1440 {
      gap: 40px 18px;
    }

    @include w360 {
      display: block;
    }
  }

  &__item {
    padding: 32px;
    width: 424px;

    svg {
      fill: var(--pink);
    }

    @include w1440 {
      width: 388px;
      padding: 24px;
    }

    @include w1024 {
      width: 320px;
      padding: 16px;
    }

     @media (max-width: 1100px) {
      width: 262px;
      padding: 16px;
    }

    @include w360 {
      width: 360px;
      max-width: 100%;
      margin: 16px auto 0;
      text-align: center;
    }
  }

  dt {
    svg {
      margin-bottom: 32px;

      @include w1440 {
        width: 48px;
        height: 48px;
      }

      @include w1024 {
        width: 32px;
        height: 32px;
        margin-bottom: 24px;
      }

      @include w360 {
        margin: 0 auto 24px;
      }
    }
  }

  dd {
    color: var(--grey);
    margin-top: var(--gap);
    font-size: 20px;
      line-height: 30px;


    @include w1440 {
      font-size: 16px;
      line-height: 24px;
    }

    @include w1024 {
      font-size: 14px;
      line-height: 24px;
    }

    @include w768 {
      font-size: 15px;
      line-height: 24px;
    }
  }
}
