.footer {
  flex-shrink: 0;
  position: relative;
  border-top: 4px;
  padding: 40px 0;
  border-top: 1px solid var(--border);

  @include w1440 {
    padding: 0;
  }

  @include w768 {
    .center {
      width: 100%;
      padding: 0 var(--indent);
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin: 70px 0;

    @include w1440 {
      margin: 66px 0 70px;
    }

    @include w1024 {
      margin: 44px 0 50px;
    }

    @include w360 {
      margin: 40px auto;
      display: block;
      max-width: 428px;
    }
  }

  &__logo {
    width: 404px;
    flex-shrink: 0;
    cursor: pointer;

    @include w1440 {
      width: 200px;
    }

    svg {
      fill: var(--blue);

      @include w1440 {
        width: 152px;
        height: 28px;
      }
    }
  }

  &__top &__logo {
    margin-top: 9px;

    @include w1440 {
      margin-top: 15px;
    }

    @include w1024 {
      display: none;
    }
  }

  &__head {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    font-family: "Outfit";

    @include w768 {
      font-size: 18px;
    }
  }

  &__menu {
    flex-shrink: 0;

    @include w360 {
      margin-bottom: 40px;
    }

    li {
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;

      @include w768 {
        font-size: 14px;
      }
    }

    a {
      color: var(--grey);
      display: flex;

      @include desktop {
        &:hover {
          color: var(--blue);
        }
      }
    }

    svg {
      fill: var(--grey);
      margin-right: 16px;
      flex-shrink: 0;
    }
  }

  hr {
    margin: 24px 0;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px 0 40px;

    @include w1440 {
      margin: 40px 0;
    }

    @include w1024 {
      margin-top: 24px;
    }

    @include w768 {
      margin: 34px 0;
    }

    @include w360 {
      margin: 24px 0 40px;
      display: block;
    }
  }

  &__bottom &__logo {
    display: none;

    @include w1024 {
      display: block;
      order: 1;
      flex-basis: 30%;

      svg {
        width: 130px;
        height: 24px;
      }
    }

    @include w360 {
      display: flex;
      width: 100%;
      margin-bottom: 22px;
      justify-content: center;
    }
  }

  &__copyright {
    font-size: 16px;
    color: var(--grey);
    order: 2;

    @include w1024 {
      font-size: 14px;
      text-align: center;
    }

    @include w768 {
      font-size: 12px;
      line-height: 1.4;
      margin-top: 30px;
    }
  }

  &__social {
    display: flex;
    order: 3;

    @include w1024 {
      flex-basis: 30%;
      display: flex;
      justify-content: flex-end;
    }

    @include w360 {
      justify-content: center;
    }

    li + li {
      margin-left: 32px;

      @include w1024 {
        margin-left: 24px;
      }

      @include w768 {
        margin-left: 12px;
      }
    }

    a {
      display: block;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: var(--blue);

      @include w1024 {
        width: 32px;
        height: 32px;

        svg {
          width: 32px;
          height: 32px;
        }
      }

      @include w768 {
        width: 24px;
        height: 24px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      @include desktop {
        &:hover {
          opacity: 0.8;
        }
      }
    }

    svg {
      fill: #fff;
    }
  }
}
