@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/source-sans-pro-v21-latin-400.woff2") format("woff2"),
    url("../fonts/source-sans-pro-v21-latin-400.woff") format("woff");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/source-sans-pro-v21-latin-600.woff2") format("woff2"),
    url("../fonts/source-sans-pro-v21-latin-600.woff") format("woff");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/source-sans-pro-v21-latin-700.woff2") format("woff2"),
    url("../fonts/source-sans-pro-v21-latin-700.woff") format("woff");
}

@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/outfit-v5-latin-400.woff2") format("woff2"),
    url("../fonts/outfit-v5-latin-400.woff") format("woff");
}

@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/outfit-v5-latin-500.woff2") format("woff2"),
    url("../fonts/outfit-v5-latin-500.woff") format("woff");
}

@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/outfit-v5-latin-600.woff2") format("woff2"),
    url("../fonts/outfit-v5-latin-600.woff") format("woff");
}

@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/outfit-v5-latin-700.woff2") format("woff2"),
    url("../fonts/outfit-v5-latin-700.woff") format("woff");
}

@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../fonts/outfit-v5-latin-800.woff2") format("woff2"),
    url("../fonts/outfit-v5-latin-800.woff") format("woff");
}
