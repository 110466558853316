.header {
  padding: 80px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  @media (min-width: 1601px) and (max-height: 1079px) {
    padding: 40px 0;
  }

  @include w1440 {
    padding: 40px 0;
  }

  @media (max-width: 1440px) and (max-height: 1079px) {
    padding: 40px 0;
  }

  @include w1024 {
    padding: 40px 0;

    .center {
      width: 100%;
      padding: 0 38px;
    }
  }

  @include w768 {
    .center {
      padding: 0 var(--indent);
    }
  }

  @include w360 {
    padding: 30px 0;
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    width: 256px;

    @include w1440 {
      width: 190px;
    }

    @include w1024 {
      width: 156px;
    }

    @include w768 {
      height: 40px;
      display: flex;
      align-items: center;
    }

    @include w360 {
      height: 24px;
    }

    svg {
      fill: #fff;

      @include w1440 {
        width: 152px;
        height: 28px;
      }

      @include w1024 {
        width: 130px;
        height: 24px;
      }
    }
  }

  &__menu {
    display: flex;

    @include w768 {
      display: none;
    }

    li {
      margin: 0 50px;

      @include w1440 {
        margin: 0 40px;
      }

      @include w1024 {
        margin: 0 24px;
        font-size: 16px;
      }

      @include w768 {
        font-size: 24px;
        line-height: 32px;
        margin: 60px 0;
        display: flex;
        justify-content: center;
      }
    }

    a {
      font-weight: 600;
      display: block;
      color: #fff;
      position: relative;

      &::before {
        transform-origin: 50% 100%;
        transition: clip-path 0.3s, transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
        clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
        content: "";
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -6px;
        left: 0;
        pointer-events: none;

        @include w1440 {
          bottom: -4px;
        }
      }

      @include desktop {
        &:hover::before {
          transform: scale3d(1, 1, 1);
          clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
        }
      }
    }
  }

  &__user {
    display: flex;

    &_mob {
      display: none;
    }

    @include w1440 {
      .btn {
        font-size: 16px;
      }
    }

    @include w768 {
      margin-left: auto;


    }

    @include w360 {
      display: none;
      width: 100%;
      order: 5;
      margin-top: auto;

       &_mob {
        display: flex !important;
        justify-content: center;
      }

      .btn {
        width: auto;
      }
    }
  }

  &__btn-menu {
    display: none;

    @include w768 {
      margin-left: 40px;
      height: 40px;
      display: flex;
      align-items: center;
    }

    @include w360 {
      height: 24px;
    }
  }
}

@include w360 {
  .btn-menu {
    width: 24px;
    height: 24px;

    &__top,
    &__center,
    &__bottom {
      width: 24px;
      height: 3px;
    }

    &__top {
      top: 14px;
    }

    &__bottom {
      bottom: 14px;
    }
  }

  .menu-show {
    background: linear-gradient(107.56deg, #41A8ED 0%, #0476C2 100%) no-repeat;;

    .header {
      &__user {
        display: flex;
      }
    }
  }
}

@include w768 {
  .btn-menu {
    width: 32px;
    height: 32px;

    &__top,
    &__center,
    &__bottom {
      position: absolute;
      width: 32px;
      height: 4px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: 2px;
      background-color: #fff;
      transition: var(--transitionDefault);
    }

    &__top {
      top: 18px;

      .menu-show & {
        top: 0;
        transform: rotate(-45deg);
      }
    }

    &__center {
      .menu-show & {
        opacity: 0;
      }
    }

    &__bottom {
      bottom: 18px;

      .menu-show & {
        bottom: 0;
        transform: rotate(45deg);
      }
    }
  }

  .menu-show {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='971' height='971' viewBox='0 0 971 971'%3E%3Cpath fill='%23fff' fill-opacity='.2' d='M971 0V971H0L971 0Z'/%3E%3Cpath fill='%23fff' fill-opacity='.2' d='M971 237.462V971H236.501L971 237.462Z'/%3E%3C/svg%3E")  no-repeat 100% 100%, linear-gradient(107.56deg, #41A8ED 0%, #0476C2 100%) no-repeat;
    background-size: 100% auto, 100% 100%;

     @include w360 {
        background: linear-gradient(107.56deg, #41A8ED 0%, #0476C2 100%) no-repeat;
    }


    .main,
    .footer {
      display: none;
    }

    .header {
      display: flex;
      flex-direction: column;
      bottom: 0;

      .center {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }

      &__flex {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
      }

      &__menu {
        display: block;
        order: 4;
        width: 100%;
      }

      &__user {
        display: none;
      }
    }
  }
}
