.pricing {
  margin: var(--space) 0;
  scroll-margin: var(--space);

  .title {
    padding-bottom: 40px;

    @include w768 {
      padding: 0;
    }
  }

  &__box {
    display: flex;
    padding: 0 35px;
    border-radius: 10px;
    background-color: #fff;
    justify-content: space-between;

    @include w1440 {
      padding: 0;
    }

    @include w768 {
      display: block;
      background-color: transparent;
    }

    @include w360 {
      width: 340px;
      max-width: 100%;
      margin: auto;
    }
  }

  &__item {
    padding: 40px;
    width: 420px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: #fff;

    @include w1440 {
      width: 390px;
    }

    @include w1024 {
      width: 280px;
      padding: 20px;
      gap: 16px;
    }

    @include w768 {
      width: 100%;
      padding: 20px 50px;
      margin-top: 24px;
      display: grid;
      gap: 40px 74px;
      grid-template-columns: 240px 234px;
      grid-template-rows: auto auto auto;
      align-items: flex-start;
    }

    @include w360 {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 20px;
    }

    &.is-popular {
      color: #fff;
      position: relative;
      margin-top: -40px;
      align-self: flex-start;
      background-color: var(--blue);

      @include w1440 {
        width: 380px;
      }

      @include w1024 {
        width: 280px;
        margin-top: -28px;
      }

      @include w768 {
        width: 100%;
        padding: 20px 50px;
        margin-top: 40px;
      }

      @include w360 {
        margin-top: 36px;
        padding: 20px;
      }
    }
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @include w768 {
      width: 240px;
      left: 50px;
      right: auto;
    }

    @include w360 {
      left: 0;
      right: 0;
      width: 100%;
    }

    &-inner {
      font-family: "Outfit";
      font-weight: 500;
      font-size: 16px;
      line-height: 48px;
      color: var(--blue);
      padding: 0 30px;
      background-color: var(--bg);
      border: 2px solid;
      border-radius: 20px;
      flex-shrink: 0;

      @include w768 {
        font-size: 12px;
        line-height: 34px;
        padding: 0 42px;
      }
    }
  }

  &__head {
    text-align: center;

    @include w1440 {
      font-size: 32px;
    }

    @include w1024 {
      font-size: 24px;
      margin: 28px 0 8px;
    }

    @include w768 {
      margin: 8px 0;

      .is-popular & {
        margin-top: 18px;
      }
    }

    @include w360 {
      width: 100%;
      margin: 4px 0;

      .is-popular & {
        margin: 18px 0 8px;
      }
    }
  }

  &__price {
    @include w1024 {
      align-self: center;
    }

    &-old {
      font-family: "Outfit", sans-serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      color: var(--color);
      text-align: center;

      .is-popular & {
        color: #fff;
      }

      s {
        margin-right: 4px;
      }

      @include w1024 {
        font-size: 14px;
        line-height: 24px;
      }
    }

    &-current {
      font-family: "Outfit";
      font-weight: 600;
      font-size: 40px;
      line-height: 56px;
      color: var(--blue);
      display: flex;
      align-items: center;
      justify-content: center;

      .is-popular & {
        color: #fff;
      }

      @include w1024 {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  &__time {
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    color: var(--grey);
    margin-left: 10px;

    .is-popular & {
      color: rgba(255, 255, 255, 0.5);
    }

    @include w1024 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  &__list {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    margin-left: 27px;

    @include w1440 {
      margin-left: 12px;

      .is-popular & {
        margin-left: 7px;
      }
    }

    @include w1024 {
      font-size: 14px;
      margin-left: 3px;

      .is-popular & {
        margin-left: 3px;
      }
    }

    @include w768 {
      grid-row-start: 1;
      grid-row-end: -1;
      grid-column-start: 2;
      grid-column-end: 3;
      margin: 0;

      .is-popular & {
        margin: 10px 0 0;
      }
    }

    @include w360 {
      width: 234px;
      max-width: 100%;
      margin: auto;

      .is-popular & {
        margin: 0;
      }
    }

    li {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none' viewBox='0 0 32 32'%3E%3Cpath stroke='%2341A8ED' stroke-width='2' d='m6.67 18.67 5.33 4L24 8'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      padding-left: 48px;

      .is-popular & {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none' viewBox='0 0 32 32'%3E%3Cpath stroke='%23fff' stroke-opacity='.5' stroke-width='2' d='m6.67 18.67 5.33 4L24 8'/%3E%3C/svg%3E");
      }

      & + li {
        margin-top: 12px;

        @include w1024 {
          margin-top: 8px;
        }
      }

      sup {
        padding: 0 7px;
        background-color: var(--pink);
        border-radius: 4px;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        margin-left: 6px;
        display: inline-block;
        vertical-align: top;
        color: #fff;
      }
    }
  }

  &__btn {
    margin-top: auto;

    .is-popular & {
      @include w1440 {
        margin: auto 10px 10px;
      }

      @include w1024 {
        margin: auto 0 0;
      }
    }

    @include w360 {
      width: 100%;
    }

    .btn {
      width: 100%;
      border-radius: 8px;
    }
  }
}
