.faq {
  background-color: #fff;
  padding: var(--space) 0;

  &__list {
    box-shadow: 2px 2px 20px rgba(13, 19, 33, 0.06);
    background-color: rgba(13, 19, 33, 0.06);
  }

  &__item {
    background-color: #fff;

    & + & {
      margin-top: 1px;
    }

    &.is-open .accordion__body {
      margin-top: -36px;

      @include w768 {
        margin-top: -24px;
      }

      @include w360 {
        margin-top: -16px;
      }
    }
  }

  &__head {
    font-family: "Outfit";
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    padding: 36px 72px 36px 40px;

    @include w1024 {
      font-size: 16px;
      line-height: 1.5;
    }

    @include w768 {
      padding: 24px 72px 24px 40px;
    }

    @include w360 {
      font-size: 14px;
      line-height: 24px;
      padding: 16px;
      padding-right: 56px;
    }
  }

  &__body {
    font-size: 20px;
    line-height: 32px;
    color: var(--grey);
    padding: 12px 40px 36px;

    @include w1024 {
      font-size: 15px;
      line-height: 24px;
      padding-right: 80px;

      br {
        display: none;
      }
    }

    @include w768 {
      padding: 12px 120px 24px 40px;
    }

    @include w360 {
      padding: 12px 56px 16px 16px;
    }

    a {
      color: var(--blue);
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
