.wrapper {
  background-color: #ffffff;
  width: 100%;
  max-height: 500px;
  height: 50vh;
  max-width: 100%;
}

@media (max-width: 533px) {

  .wrapper {
    height: 30vh;
  }

}