.visible-md,
.visible-sm {
  display: none;
}

.center {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

@include w1440 {
  :root {
    --space: 140px;
  }
  .center {
    width: 1200px;
  }
}

@include w1024 {
  :root {
    --indent: 80px;
    --gap: 8px;
    --space: 80px;
  }
  .center {
    width: 1200px;
    padding: 0 38px;
  }
}

@include w768 {
  :root {
    --indent: 60px;
    --space: 100px;
  }
  .center {
    width: 768px;
    padding: 0 var(--indent);
  }
}

@include w360 {
  :root {
    --indent: 20px;
    --space: 70px;
  }
  .center {
    width: 468px;
  }
}
