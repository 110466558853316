.cite-testimonials {
  margin: var(--space) 0;
  scroll-margin: var(--space);

  &__blockquote {
    padding-top: 36px;
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='120' height='96' fill='none' viewBox='0 0 120 96'%3E%3Cpath fill='%2341A8ED' d='M52.48 0v25.26c0 7.68-1.39 15.86-4.15 24.56a102.16 102.16 0 0 1-11.87 25.13A88.45 88.45 0 0 1 17.68 96L0 81.4a181.56 181.56 0 0 0 15.05-26.24 67.88 67.88 0 0 0 6.5-29.34V0h30.93ZM120 0v25.26c0 7.68-1.38 15.86-4.14 24.56a102.15 102.15 0 0 1-11.88 25.13A88.44 88.44 0 0 1 85.2 96L67.52 81.4a181.56 181.56 0 0 0 15.06-26.24 67.88 67.88 0 0 0 6.49-29.34V0H120Z' opacity='.06'/%3E%3C/svg%3E");

    @include w1440 {
      font-size: 32px;
      line-height: 48px;
    }

    @include w1024 {
      font-size: 24px;
      line-height: 40px;
    }

    @include w360 {
      font-size: 16px;
      line-height: 24px;
      padding-top: 18px;
      background-size: 60px 48px;
    }
  }

  &__cite {
    margin-top: 80px;
    font-style: normal;
    font-family: "Outfit", sans-serif;
    display: flex;
    justify-content: center;
    vertical-align: center;
    gap: 24px;

    @include w1440 {
      font-size: 24px;
      line-height: 32px;
      margin-top: 60px;
    }

    @include w1024 {
      font-size: 16px;
      line-height: 24px;
      margin-top: 40px;
    }

    @include w768 {
      gap: 16px;
    }

    @include w360 {
      font-size: 12px;
      margin-top: 20px;
    }
  }

  &__text {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;

    @include w1024 {
      gap: 12px;
    }
    @include w360 {
      gap: 8px;
    }
  }

  &__img {
    width: 96px;
    height: 96px;

    @include w1440 {
      width: 80px;
      height: 80px;
    }

    @include w1024 {
      width: 60px;
      height: 60px;
    }
  }


  &__name {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #1B2A47;

    @include w1440 {
      font-size: 24px;
      line-height: 40px;
    }

    @include w1024 {
      font-size: 18px;
      line-height: 24px;
    }


    @include w360 {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__job {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #41A8ED;
    font-family: "Source Sans Pro", sans-serif;

    @include w1440 {
      font-size: 18px;
      line-height: 24px;
    }
    @include w1024 {
      font-size: 15px;
      line-height: 24px;
    }
    @include w360 {
      font-size: 12px;
      line-height: 20px;
    }
  }

}
