.how-it-work {
  background-color: #FAFAFA;
  padding: var(--space) 0;

  &__text {
    color: #6E7E9D;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 32px;
  }

  &__text:last-child {
    margin-bottom: 0;
  }
}
