.first-screen {
  position: relative;
  padding: 214px 0 150px;
  color: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 600px;
  height: 100vh;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='971' height='971' viewBox='0 0 971 971'%3E%3Cpath fill='%23fff' fill-opacity='.2' d='M971 0V971H0L971 0Z'/%3E%3Cpath fill='%23fff' fill-opacity='.2' d='M971 237.462V971H236.501L971 237.462Z'/%3E%3C/svg%3E") no-repeat 100% 100%, linear-gradient(107.56deg, #41A8ED 0%, #0476C2 100%) no-repeat;

  @media (min-width: 1601px) and (max-height: 1079px) {
    padding: 132px 0 46px;
  }

  @media (min-width: 1601px) and (max-height: 899px) {
    padding: 132px 0 46px;

    //.center {
    //  width: 1308px;
    //}
  }

  @media (min-width: 1601px) and (max-height: 749px) {
    padding: 132px 0 46px;

    //.center {
    //  width: 1000px;
    //}
  }

  @include w1440 {
    padding: 132px 0 46px;
    min-height: 650px;
  }

  @media (max-width: 1439.9px) {
    min-height: 600px;
  }

  @media (max-width: 1440px) and (min-width: 1301px) and (max-height: 749px) {
    //.center {
    //  width: 1000px;
    //}
  }

  .wrapperCenter {
    //height: calc(100% - 364px);
    //width: 1440px;
    max-width: 100%;
    margin: 0 auto;
    position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translateY(-50%) translateX(-50%);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 940px) {
      flex-direction: column;
      position: initial;
      transform: translateY(0) translateX(0);
    }

    @media (min-width: 1600px) {
      min-height: 650px;
    }

    @media (min-width: 1900px) {
      min-height: 600px;
    }

  }

  @media (min-width: 1601px) and (max-height: 899px) {
    padding: 132px 0 46px;

    //.wrapperCenter {
    //  width: 1308px;
    //}
  }

  @media (min-width: 1601px) and (max-height: 749px) {
    padding: 132px 0 46px;

    //.wrapperCenter {
    //  width: 1000px;
    //}
  }

  @media (max-width: 1440px) and (min-width: 1301px) and (max-height: 749px) {
    //.wrapperCenter {
    //  width: 1000px;
    //}
  }


  @include w1024 {
    padding: 120px 0 46px;
    background-size: 511px 511px, 100% 100%;
  }

  @include w768 {
    padding: 152px 0 0;
    background-size: 100% auto, 100% 100%;
    height: auto;
  }

  @include w360 {
    padding: 104px 0 28px;
  }

  @media (max-width: 499.9px) {
    padding: 104px 0 0;
    align-items: end;
    min-height: auto;
    height: auto;
  }

  &__flex {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    position: relative;
    align-items: stretch;
    align-content: stretch;


    @include w768 {
      display: block;
    }
  }

  &__flexB2b {
    position: relative;
    @media (min-width: 1024px) {
      display: flex;
      justify-content: space-between;
      gap: 150px;
      align-items: stretch;
      align-content: stretch;
    }

  }


  &__content {
    position: relative;

    @media (min-width: 1601px) and (max-height: 899px) {
      max-width: 600px;
    }

    @media (min-width: 1601px) and (max-height: 749px) {
      max-width: 400px;
    }

    @include w1440 {
      max-width: 600px;
    }

    @media (max-width: 1440px) and (min-width: 1301px) and (max-height: 749px) {
      max-width: 400px;
    }

    @media (max-width: 1100px) {
      max-width: 400px;
    }

    @include w768 {
      max-width: 380px;
      margin: auto;
    }

    @include w360 {
      max-width: 340px;
    }
  }

  &__contentB2b {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;


  }

  &__cloud {
    &-1 {
      position: absolute;
      top: -48px;
      right: 272px;
      width: auto;
      height: auto;

      @include w1440 {
        top: -90px;
        right: 150px;
      }

      @include w1024 {
        top: -16px;
        right: 42px;
      }

      @include w768 {
        top: 62px;
        right: -90px;
      }

      @include w360 {
        display: none;
      }
    }

    &-2 {
      position: absolute;
      bottom: 128px;
      right: 52px;
      width: auto;
      height: auto;

      @media (min-width: 1601px) and (max-height: 899px) {
        right: -70px;
      }

      @include w1440 {
        bottom: 85px;
        right: -65px;
      }

      @include w1024 {
        bottom: 66px;
        right: -42px;
      }

      @include w768 {
        bottom: -480px;
        right: -146px;
      }

      @include w360 {
        display: none;
      }
    }

    &-3 {
      position: absolute;
      bottom: -125px;
      left: -66px;
      width: auto;
      height: auto;

      @include w1440 {
        bottom: -117px;
        left: -33px;
      }

      @include w1024 {
        bottom: -53px;
        left: -22px;
      }

      @include w768 {
        bottom: -24px;
        left: -136px;
      }

      @include w360 {
        display: none;
      }
    }
  }

  &__discounter {
    display: block;
    width: fit-content;
    padding: 0 24px;
    align-items: center;
    font-family: 'Outfit', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    color: #FFFFFF;
    margin-bottom: 40px;
    background: linear-gradient(90.75deg, #068CE6 0%, #29C6F8 100%);
    border-radius: 30px;

    @include w1440 {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 32px;
    }

    @include w1024 {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 32px;
    }

    @media (max-width: 1100px) {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 32px;
    }

    @media (min-width: 1024px) and (max-height: 750px) {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 24px;
    }

    @include w768 {
      font-size: 14px;
      line-height: 24px;
      justify-content: center;
      margin: 0 auto 24px;
    }

    @include w360 {
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }

  &__title {
    margin-bottom: 50px;

    @media (min-width: 1601px) and (max-height: 899px) {
      font-size: 90px;
      line-height: 100px;
      margin-bottom: 60px;
    }

    @media (min-width: 1601px) and (max-height: 749px) {
      font-size: 68px;
      line-height: 74px;
      margin-bottom: 34px;
    }

    @include w1440 {
      margin-bottom: 60px;
    }

    @media (max-width: 1440px) and (min-width: 1301px) and (max-height: 749px) {
      font-size: 68px;
      line-height: 74px;
      margin-bottom: 34px;
    }

    @media (max-height: 750px) {
      font-size: 56px;
      line-height: 56px;
    }

    @include w1024 {
      margin-bottom: 34px;
    }

    @include w768 {
      text-align: center;
      margin-bottom: 24px;
    }
  }

  &__titleB2b {
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 56px;
    text-align: center;
    font-family: "Outfit", sans-serif;


    @media (min-width: 1024px) {
      font-size: 40px;
      line-height: 56px;
      text-align: left;
    }

    @media (min-width: 1440px) {
      font-size: 56px;
      line-height: 64px;
      margin-bottom: 32px;
      text-align: left;
    }

    @media (min-width: 1920px) {
      font-size: 64px;
      line-height: 88px;
    }
  }

  &__text {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 48px;

    @media (min-width: 1601px) and (max-height: 899px) {
      font-size: 28px;
      margin-bottom: 40px;
    }

    @media (min-width: 1601px) and (max-height: 749px) {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 30px;
    }

    @include w1440 {
      font-size: 28px;
      margin-bottom: 40px;
    }

    @media (max-width: 1440px) and (min-width: 1301px) and (max-height: 749px) {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 30px;
    }

    @media (max-height: 750px) {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 30px;
    }

    @include w1024 {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 35px;
    }

    @media (max-width: 1100px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 30px;
    }

    @include w768 {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }

    @include w360 {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 16px;
    }
  }


  &__textB2b {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;


    @media (min-width: 1024px) {
      margin-bottom: 16px;
    }

    @media (min-width: 1440px) {
      font-size: 20px;
      line-height: 32px;
    }

    @media (min-width: 1920px) {
      font-size: 24px;
      line-height: 40px;
    }


  }

  &__btn {
    display: flex;
    width: 300px;

    @media (min-width: 1601px) and (max-height: 899px) {
      margin-bottom: 0;

      .btn {
        font-size: 18px;
        height: 64px;
        line-height: 64px;
      }
    }

    @media (min-width: 1601px) and (max-height: 749px) {
      width: 250px;

      .btn {
        font-size: 16px;
        height: 48px;
        line-height: 48px;
      }
    }

    @include w1440 {
      margin-bottom: 0;
    }

    @media (max-width: 1440px) and (min-width: 1301px) and (max-height: 749px) {
      width: 300px;

      //.btn {
      //  font-size: 16px;
      //  height: 48px;
      //  line-height: 48px;
      //}
    }

    @media (max-width: 1100px) {
      width: 250px;
      margin-bottom: 24px;
    }

    @include w768 {
      width: 100%;
      margin-bottom: 20px;
    }

    @include w360 {
      margin-bottom: 20px;
    }

    .btn {
      width: 100%;
    }
  }

  &__offer {
    position: relative;
    width: 100%;
    flex-shrink: 0;
    background-color: transparent;
    border-radius: 10px;
    color: var(--color);
    display: flex;
    flex-direction: column;
    justify-content: center;


    @media (min-width: 1024px) {
      max-width: 446px;
    }

    @media (min-width: 1440px) {
      max-width: 550px;
    }
  }


  &__leftBlock {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-height: 1000px;
    min-height: 400px;
    height: 840px;

    @media (min-width: 940px) {
      height: 520px;
      top: 50%;
    }

    //@media (min-width: 940px) and (max-height: 700px) {
    //  height: 480px;
    //  top:50%;
    //}

    @media (min-width: 940px) and (max-height: 600px) {
      height: 480px;
      top: 50%;
    }


    @media (min-width: 1000px) {
      height: 520px;
      top: 51%;
    }

    @media (min-width: 1000px) and (max-height: 650px) {
      height: 520px;
      top: 52%;
    }


    @media (min-width: 1101px) {
      height: 600px;
      top: 52%;
    }

    @media (min-width: 1100px) and (max-height: 650px) {
      height: 600px;
      top: 52%;
    }

    @media (min-width: 1200px) {
      height: 670px;
      top: 51%;
    }

    @media (min-width: 1200px) and (max-height: 1000px) {
      height: 650px;
      top: 52%;
    }

    @media (min-width: 1200px) and (max-height: 750px) {
      height: 600px;
      top: 52%;
    }

    @media (min-width: 1200px) and (max-height: 650px) {
      height: 600px;
      top: 53%;
    }

    @media (min-width: 1301px) {
      height: 770px;
      top: 52%;
    }

    //@media (min-width: 1301px) and (max-height: 1000px) {
    //  height: 700px;
    //  top: 60%;
    //}

    //@media (min-width: 1301px) and (max-height: 900px) {
    //  height: 730px;
    //  top: 60%;
    //}
    //
    //@media (min-width: 1301px) and (max-height: 850px) {
    //  height: 700px;
    //  top: 56%;
    //}

    //@media (min-width: 1301px) and (max-height: 800px) {
    //  height: 750px;
    //  top: 52%;
    //}

    @media (min-width: 1301px) and (max-height: 750px) {
      height: 600px;
      top: 52%;
    }

    //@media (min-width: 1301px) and (max-height: 700px) {
    //  height: 500px;
    //  top: 56%;
    //}

    @media (min-width: 1301px) and (max-height: 650px) {
      height: 550px;
      top: 53%;
    }

    @media (min-width: 1440px) {
      height: 800px;
      top: 52%;
    }

    //@media (min-width: 1440px) and (max-height: 1000px) {
    //  height: 750px;
    //  top: 52%;
    //}
    //
    //@media (min-width: 1440px) and (max-height: 900px) {
    //  height: 730px;
    //  top: 52%;
    //}

    @media (min-width: 1440px) and (max-height: 850px) {
      height: 770px;
      top: 53%;
    }

    @media (min-width: 1440px) and (max-height: 800px) {
      height: 750px;

      top: 54%;
    }

    @media (min-width: 1440px) and (max-height: 750px) {
      height: 600px;

      top: 52%;
    }

    @media (min-width: 1440px) and (max-height: 700px) {
      height: 580px;

      top: 53%;
    }

    @media (min-width: 1440px) and (max-height: 650px) {
      height: 570px;

      top: 54%;
    }

    //@media (min-width: 1440px) and (max-height: 600px) {
    //  height: 550px;
    //
    //  top: 52%;
    //}

    @media (min-width: 1600px) {
      height: 900px;
      top: 52%;
    }

    @media (min-width: 1600px) and (max-height: 1000px) {
      height: 860px;
      top: 52%;
    }

    @media (min-width: 1600px) and (max-height: 900px) {
      height: 840px;

      top: 53%;
    }

    //@media (min-width: 1600px) and (max-height: 850px) {
    //  height: 750px;
    //
    //  top: 53%;
    //}
    //
    @media (min-width: 1600px) and (max-height: 800px) {
      height: 780px;

      top: 53%;
    }
    @media (min-width: 1600px) and (max-height: 750px) {
      height: 600px;
      top: 54%;
    }

    //@media (min-width: 1600px) and (max-height: 700px) {
    //  height: 600px;
    //  top: 53%;
    //}
    //@media (min-width: 1600px) and (max-height: 650px) {
    //  height: 500px;
    //
    //  top: 51%;
    //}
    //

    @media (min-width: 1900px) {
      height: 950px;
      top: 52%;
    }

    @media (min-width: 1900px) and (max-height: 1000px) {
      height: 850px;
    }

    @media (min-width: 1900px) and (max-height: 900px) {
      height: 800px;
      top: 53%;
    }

    @media (min-width: 1900px) and (max-height: 850px) {
      height: 780px;
      top: 53%;
    }
    //
    //@media (min-width: 1900px) and (max-height: 800px) {
    //  height: 600px;
    //
    //   top: 70%;
    //}

    @media (min-width: 1900px) and (max-height: 749px) {
      height: 630px;

      top: 52%;
    }

    @media (min-width: 1900px) and (max-height: 700px) {
      height: 550px;
      top: 51%;
    }

    @media (min-width: 1900px) and (max-height: 650px) {
      height: 520px;
      top: 53%;
    }


    @media (max-width: 940px) {
      position: initial;
      justify-content: center;
      align-items: center;
      transform: translateY(0);
      height: auto;
      min-height: 0;
    }
  }

  &__dashboardDesktopImg {
    height: 100%;
    object-fit: cover;
    @media (max-width: 940px) {
      display: none;
    }
    @media (max-width: 499.9px) {

    }
  }

  &__dashboardTabletImg {
    display: none;
    @media (max-width: 940px) {
      display: block;
      width: 100%;
      height: auto;
    }
    @media (max-width: 499.9px) {
      display: none;
    }
  }

  &__dashboardMobileImgMobile {
    display: none;
    @media (max-width: 499.9px) {
      display: block;
      width: 100%;
    }
  }

}

.first-screen-corporate {
  height: auto;
  padding-bottom: 20px;
  @media (min-width: 768px) {
    padding-bottom: 60px;
  }
}

.list-items {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.list-item-square {
  padding-left: 34px;
  position: relative;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
  text-align: left;


  @media (min-width: 768px) {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  @media (min-width: 1440px) {
    font-size: 20px;
    line-height: 32px;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 32px;
  }
}

.list-item-square:last-child {
  margin-bottom: -0px;
}

.list-item-square:before {
  position: absolute;
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

}
