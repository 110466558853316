fieldset {
  border: 0;
  min-width: 0;
  display: block;
  position: relative;

  &:focus {
    outline: none;
  }
}

legend {
  display: table;

  &:focus {
    outline: none;
  }
}

.btn,
.button {
  border: 0;
  border-radius: 0;
  font-family: inherit;
  background-color: transparent;
  transition: var(--transitionDefault);
  user-select: none;
  position: relative;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  display: block;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
  }
}

.btn {
  border-radius: 10px;
  line-height: 52px;
  padding: 0 40px;
  height: 52px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Outfit";

  @include w1440 {
    font-size: 18px;
  }

  @include w1024 {
    font-size: 16px;
    line-height: 40px;
    padding: 0 30px;
    height: 40px;
  }

  &--big {
    height: 72px;
    line-height: 72px;

    @include w1440 {
      height: 64px;
      line-height: 64px;
    }

    @media (max-width: 1100px) {
      height: 48px;
      line-height: 48px;
    }
  }

  &--blue {
    color: #fff;
    background-color: var(--blue);

    @include desktop {
      &:hover {
        background-color: #008ede;
      }
    }
  }

  &--white {
    background-color: #fff;
    color: var(--pink);

    @include desktop {
      &:hover {
        color: #fff;
        background-color: var(--pink);
      }
    }
  }

  &--outline {
    color: #fff;
    box-shadow: inset 0 0 0 2px #fff;

    @include desktop {
      &:hover {
        background-color: var(--pink);
        box-shadow: inset 0 0 0 2px var(--pink);
      }
    }
  }

  &--link {
    color: #fff;

    &::before {
      transform-origin: 50% 100%;
      transition: clip-path 0.3s, transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
      clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
      content: "";
      background-color: #fff;
      position: absolute;
      height: 2px;
      bottom: 4px;
      left: 40px;
      right: 40px;

      @include w1440 {
        bottom: 10px;
      }

      @include w1024 {
        bottom: 2px;
        left: 30px;
        right: 30px;
      }
    }

    @include desktop {
      &:hover::before {
        transform: scale3d(1, 1, 1);
        clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
      }
    }
  }
}

.input {
  line-height: 22px;
  padding: 16px 32px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color);

  width: 100%;
  font-family: inherit;
  position: relative;
  appearance: none;
  display: block;
  filter: none;
  -webkit-transform: translateZ(0px);

  background-color: #fff;
  box-shadow: inset 0 0 0 100px #fff;

  height: 54px;
  border: 1px solid var(--border);
  border-radius: 12px;

  textarea & {
    height: 126px;
    resize: none;
    overflow: auto;

    scrollbar-width: thin;
    scrollbar-color: var(--placeholder) transparent;

    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 3px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: var(--placeholder);
    }
  }

  &::placeholder {
    opacity: 1;
    color: var(--placeholder);
  }

  &::-ms-clear {
    display: none;
  }

  &:hover:not(:focus) {
    box-shadow: 0 4px 16px rgba(255, 142, 36, 0.12);
  }

  &:focus {
    outline: none;
    border-color: var(--orange);
    box-shadow: 0 4px 16px rgba(255, 142, 36, 0.12) !important;
  }

  &:disabled {
    pointer-events: none;
  }

  &:invalid {
    box-shadow: none;
  }
}
