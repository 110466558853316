@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: SourceSansPro, system-ui, sans-serif;
  }
}

html,
body {
  min-height: 100%;
  /*font-size: 10px;*/
}

body {
  margin: 0;
  font-family: SourceSansPro, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.noneScroll {
  overflow: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

img {
  max-width: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  line-height: 1;
}

ol,
ul,
li {
  list-style: none;
}

a,
a:hover {
  text-decoration: none;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
}

input {
  box-shadow: none !important;
}

select::ms-expand {
  display: none;
}

input::-ms-clear {
  display: none;
}

.form-block {
  background: url("./Images/form_bottom_bg.png") 0 100% no-repeat, url("./Images/form_top_bg.png") 100% 0 no-repeat,
  #41a8ed;
  background-size: 30rem, 30rem, auto;
}

.error .input {
  background-color: #fff;
}

.error .error-text {
  display: inline;
}

.custom-list li::before {
  content: "";
  width: 1em;
  height: 0.6875em;
  background: url("./Images/check_red.svg") 0 0 no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0.4em;
}

#overlay {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 80;
}

.faqLabel:checked ~ div {
  display: block;
}
