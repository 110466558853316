* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;

  &.scroll-behavior-off {
    scroll-behavior: auto;
  }
}

body {
  min-width: 320px;
  color: var(--color);
  font-size: 20px;
  line-height: 1.6;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: scrollbar;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
  background-color: var(--bg);

  @include w1440 {
    font-size: 16px;
    line-height: 1.5;
  }

  @include w1024 {
    font-size: 14px;
    line-height: 1.71;
  }

  background-repeat: no-repeat;
  /* background-image: url(/css/360.png); */
  background-position: center 0px;
}

a {
  color: var(--color);
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

::selection {
  color: #fff;
  background-color: var(--pink);
}

img {
  border: 0;
  display: block;
  position: relative;
}

svg {
  display: block;
  position: relative;
  // fill: var(--pink);
  transition: var(--transitionDefault);
}

li {
  list-style: none;
  position: relative;
}

hr {
  border: 0;
  height: 1px;
  background-color: var(--border);
}

.h1 {
  font-weight: 700;
  font-size: 120px;
  line-height: 120px;
  font-family: "Outfit", sans-serif;

  @include w1440 {
    font-size: 84px;
    line-height: 90px;
  }

  @include w1024 {
    font-size: 70px;
    line-height: 74px;
  }

   @media (max-width: 1100px) {
      font-size: 56px;
    line-height: 56px;
    }

  @include w768 {
    font-size: 64px;
    line-height: 1;
  }

  @include w360 {
    font-size: 48px;
  }
}

.h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  font-family: "Outfit";

  @include w1440 {
    font-size: 40px;
    line-height: 56px;
  }

  @include w1024 {
    font-size: 32px;
    line-height: 40px;
  }

  @include w360 {
    font-size: 24px;
    line-height: 32px;
  }
}

.h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  font-family: "Outfit", sans-serif;

  @include w1440 {
    font-size: 24px;
  }

  @include w1024 {
    font-size: 18px;
    line-height: 24px;
  }
}

.title {
  text-align: center;
  margin-bottom: 80px;

  @include w1024 {
    margin-bottom: 40px;
  }
}

/*
-----------------------------------------------------------------------------*/
.main {
  flex-shrink: 0;
  flex-grow: 1;
}

.wrapper {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* hidden
-----------------------------------------------------------------------------*/
.hide {
  display: none !important;
}

.visuallyhidden {
  position: absolute !important;

  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;

  clip: rect(0 0 0 0) !important;
  overflow: hidden !important;
}

.nowrap {
  white-space: nowrap;
}
